import ListingActions from "../../components/quizzes/listing/listing-actions.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import QuizBox from "../../components/quizzes/listing/quiz-box.jsx";
import useQuizListing from "../../hooks/quizzes/use-quiz-listing.js";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function HomeworkListing({ quizType }) {
    const [homework, setHomework] = useState(null);
    const { renderListing, createQuiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility } = useQuizListing(
        quizType,
        setHomework,
    );

    useEffect(() => {
        renderListing().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <ListingActions
                        newQuizAction={createQuiz}
                        refresh={renderListing}
                        newQuizzButtonName={"Adaugare tema pentru casa"}
                    />
                    <div className="flex flex-col gap-1 pt-4 text-lg">
                        <p>
                            <strong>Atenție! Doar prima dată când completezi o lecție primești puncte.</strong>
                        </p>
                        <ul>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Pornire{`"`}
                                </strong>
                                , înseamnă că nu ai făcut tema pentru acasă și vei fi punctat după completare.
                            </li>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Continuare{`"`}
                                </strong>
                                , înseamnă că ai început tema, dar nu ai terminat-o. Vei primi puncte după ce o termini.
                            </li>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Pornire (Nepunctată){`"`}
                                </strong>
                                , înseamnă că ai terminat tema și ai primit deja punctele pentru prima completare.
                            </li>
                        </ul>
                    </div>
                    <div className="grid grid-cols-1 gap-4 py-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                        {Array.isArray(homework) &&
                            homework.length > 0 &&
                            homework.map((h) => (
                                <QuizBox
                                    quiz={h}
                                    key={h.id}
                                    redirectToQuiz={redirectToQuiz}
                                    changeQuizVisibility={changeQuizVisibility}
                                    startQuizCompletion={startQuizCompletion}
                                />
                            ))}
                    </div>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
HomeworkListing.propTypes = {
    quizType: PropTypes.string.isRequired,
};
