import { QUIZ_TYPES } from "./pages/quizzes/quiz-creation.jsx";
import { twMerge } from "tailwind-merge";
import axios from "axios";
import { clsx } from "clsx";
import _ from "lodash";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export async function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function pathToRegex(path) {
    return new RegExp("^" + path.replace(/:[^\s/]+/g, "([^/]+)").replace(/\//g, "\\/") + "$");
}

export function formatUnixTimestamp(unixTimestamp) {
    if (!unixTimestamp || isNaN(unixTimestamp)) return null;

    const newDate = new Date(unixTimestamp * 1000);
    return newDate.toLocaleString("ro-RO", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
}

export function handleBackendDown(error) {
    const response = { isBackendDown: false, message: "" };
    if (error && axios.isAxiosError(error)) {
        if (!error?.response?.headers) {
            response.isBackendDown = true;
            response.message = "Pentru moment intampinam dificultati tehnice. Vom reveni in cel mai scurt timp!";
        }
    }
    return response;
}

export function capitalizeName(str) {
    if (typeof str !== "string") return "";
    if (str.length === 1) return str.toUpperCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function generateUUID() {
    return crypto.randomUUID();
}

export function findChangedPath(newData, oldData, path = "") {
    if (_.isEqual(newData, oldData)) return null;

    if (Array.isArray(newData)) {
        if (!Array.isArray(oldData) || newData.length !== oldData.length) return path;
        for (let i = 0; i < newData.length; i++) {
            const newPath = `${path}[${i}]`;
            const result = findChangedPath(newData[i], oldData[i], newPath);
            if (result) return result;
        }
    } else if (typeof newData === "object" && newData !== null) {
        for (const key in newData) {
            if (Object.prototype.hasOwnProperty.call(newData, key)) {
                const newPath = path ? `${path}.${key}` : key;
                if (!oldData || !Object.prototype.hasOwnProperty.call(oldData, key)) return newPath;
                const result = findChangedPath(newData[key], oldData[key], newPath);
                if (result) return result;
            }
        }
    } else return path;
    return null;
}

export function checkAnyErrorForPath(path, errors) {
    if (typeof path !== "string") return false;
    for (const key in errors) {
        if (key.startsWith(path)) return true;
    }
    return false;
}

export function retrieveErrorForPath(path, errors) {
    if (typeof path !== "string") return "";
    for (const key in errors) {
        if (key === path) return errors[key].toString();
    }
    return "";
}

export function setValueAtPath(obj, path, value) {
    const keys = path.split(".");
    keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
            acc[key] = value;
        } else {
            if (!acc[key]) acc[key] = {};
            return acc[key];
        }
    }, obj);
}

export function getQuizPlaceholder(quizType, version) {
    const quizPlaceholders = {
        [QUIZ_TYPES.LESSON]: {
            1: "lectiei",
            2: "lectie",
            3: "lectia",
            4: "Lectia",
            5: "lectii",
        },
        [QUIZ_TYPES.HOMEWORK]: {
            1: "temei pentru acasa",
            2: "tema pentru acasa",
            3: "tema pentru acasa",
            4: "Tema pentru acasa",
            5: "teme pentru acasa",
        },
        [QUIZ_TYPES.PRACTICAL_ACTIVITY]: {
            1: "activitatii practice",
            2: "activitate practica",
            3: "activitatea practica",
            4: "Activitatea practica",
            5: "activitati practice",
        },
        [QUIZ_TYPES.MONTHLY_CHALLENGE]: {
            1: "provocarii",
            2: "provocare",
            3: "provocarea",
            4: "Provocarea",
            5: "provocari",
        },
        // [QUIZ_TYPES.DAILY_CHALLENGE]: {
        //     1: "provocarii zilnice",
        //     2: "provocare zilnica",
        //     3: "provocarea zilnica",
        //     4: "Provocarea zilnica",
        //     5: "provocari zilnice",
        // },
    };
    if (!quizPlaceholders[quizType][version]) throw new Error("Invalid parameters");
    return quizPlaceholders[quizType][version];
}

export function generateFutureDate(days) {
    const currentDate = new Date();
    const futureDate = addFewDays(currentDate, days);
    futureDate.setSeconds(0);
    futureDate.setMilliseconds(0);
    return Math.floor(futureDate.getTime() / 1000);
}

function addFewDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function trimStringsFromAnywhere(something) {
    let newThing = JSON.parse(JSON.stringify(something));

    function trimHelper(item) {
        if (typeof item === "string") {
            return item.trim();
        } else if (Array.isArray(item)) {
            return item.map(trimHelper);
        } else if (item && typeof item === "object") {
            for (const key in item) {
                if (Object.prototype.hasOwnProperty.call(item, key)) {
                    item[key] = trimHelper(item[key]);
                }
            }
        }
        return item;
    }

    return trimHelper(newThing);
}

export const handleAxiosError = (error) => {
    if (error.message === "Network Error") {
        const networkError = {
            response: { data: { message: "Eroare de rețea: Vă rugăm să verificați conexiunea la internet." } },
        };
        return Promise.reject(networkError);
    }

    if (error.response && error.response.status === 500) {
        const serverError = {
            response: {
                data: {
                    message:
                        "Am intampinat o eroare necunoscută. Vă rugăm să încercați din nou mai târziu sau contactați echipa de suport tehnic.",
                },
            },
        };
        return Promise.reject(serverError);
    }

    return Promise.reject(error);
};
