import { toastOneWarn, updateFailureToast, updateSuccessToast } from "../../toast.js";
import { useAuthProviderV2, useFetchProvider } from "../../providers/provider-hooks.jsx";
import { delay, findChangedPath } from "../../utils.js";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";

const defaultForm = Object.freeze({ currentPassword: "", newPassword: "" });
const diacritics = Object.freeze(["ă", "â", "î", "ș", "ț", "Ă", "Â", "Î", "Ș", "Ț"]);

export default function usePasswordChange() {
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(JSON.parse(JSON.stringify(defaultForm)));
    const [oldFormData, setOldFormData] = useState(JSON.parse(JSON.stringify(defaultForm)));

    const { updateRefreshToken, updateToken } = useAuthProviderV2();
    const { patchPassword } = useFetchProvider();
    const openModal = () => setIsModalOpen(true);

    function handleFieldChange(e) {
        const { name, value } = e.target;
        if (diacritics.includes(value)) return toastOneWarn("Parola nu poate sa contina diacritice.");
        const updatedData = { ...formData };
        updatedData[name] = value;
        setFormData(updatedData);
    }

    async function updatePassword() {
        const passwordUpdateError = "Parola nu s-au putut actualiza.";
        const id = toast.loading("Parola este in curs de actualizare...", { transition: Slide });
        try {
            await delay(1000);
            const { token, refreshToken } = await patchPassword(formData);
            updateSuccessToast("Parola a fost actualizata", id);
            updateRefreshToken(refreshToken);
            updateToken(token);
            setErrors({});
            setOldFormData(JSON.parse(JSON.stringify(defaultForm)));
            setFormData(JSON.parse(JSON.stringify(defaultForm)));
            setIsModalOpen(false);
            updateSuccessToast("Parola a fost actualizata", id);
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast(passwordUpdateError, id);
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(formData, oldFormData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldFormData(_.cloneDeep(formData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, errors]);

    useEffect(() => {
        if (isModalOpen) {
            setErrors({});
            setOldFormData(JSON.parse(JSON.stringify(defaultForm)));
            setFormData(JSON.parse(JSON.stringify(defaultForm)));
        }
    }, [isModalOpen]);

    return { openModal, setIsModalOpen, isModalOpen, formData, handleFieldChange, errors, updatePassword };
}
