import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { QUIZ_TYPES } from "../../../pages/quizzes/quiz-creation.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import { QUIZ_START_TYPES } from "./quiz-box.jsx";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function PupilButtons({ quiz, startQuizCompletion }) {
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    if (userInfo?.role === ROLES.ELEV)
        return (
            <Fragment>
                {quiz.type === QUIZ_TYPES.PRACTICAL_ACTIVITY && (
                    <Fragment>
                        <Button
                            variant={"link"}
                            className={"w-full, sm:w-auto"}
                            onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.PREVIEW)}>
                            <span className={"text-wrap"}>Vizualizare</span>
                        </Button>
                    </Fragment>
                )}
                {[QUIZ_TYPES.HOMEWORK, QUIZ_TYPES.MONTHLY_CHALLENGE].includes(
                    quiz.type,
                ) && (
                    <Fragment>
                        {quiz["hasLastVersionFullyCompleted"] === null && (
                            <Button
                                variant={"link"}
                                className={"w-full, sm:w-auto"}
                                onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.NEW)}>
                                <span className={"text-wrap"}>Pornire</span>
                            </Button>
                        )}
                        {quiz["hasLastVersionFullyCompleted"] === false && (
                            <Button
                                variant={"link"}
                                className={"w-full, sm:w-auto"}
                                onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.OLD)}>
                                <span className={"text-wrap"}>Continuare</span>
                            </Button>
                        )}
                        {quiz["hasLastVersionFullyCompleted"] === true && (
                            <Button
                                variant={"link"}
                                className={"w-full, sm:w-auto"}
                                onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.PREVIEW)}>
                                <span className={"text-wrap"}>Pornire (Nu se mai puncteaza)</span>
                            </Button>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
}
PupilButtons.propTypes = {
    quiz: PropTypes.object.isRequired,
    startQuizCompletion: PropTypes.func.isRequired,
};
