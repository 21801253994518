import MarkdownPreview from "../../../forms/raw-inputs/preview-markdown.jsx";
import MarkdownEditor from "../../../forms/raw-inputs/markdown-editor.jsx";
import useTextAreaResize from "../../../../hooks/use-text-area-resize.js";
import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import ImageUpload from "./question-slide/image-upload.jsx";
import ErrorSpan from "../../../layout/error-span.jsx";
import PreviewTextBox from "../preview-text-box.jsx";
import { Fragment, useEffect, useMemo } from "react";
import QuizWrap from "../quiz-wrap.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FaImage } from "react-icons/fa6";

export default function InfoSlide({
    formData,
    selectedSlide,
    isInPreview,
    changeSlideTitle,
    changeSlideDescription,
    checkAnyErrorForPath,
    retrieveErrorForPath,
    changeSlideImage,
}) {
    const slide = useMemo(() => formData.slides.find((slide) => slide.id === selectedSlide), [selectedSlide, formData]);
    const slideIdx = useMemo(
        () => formData.slides.findIndex((slide) => slide.id === selectedSlide),
        [selectedSlide, formData],
    );
    const { ref: titleRef, resizeTextarea } = useTextAreaResize();

    useEffect(() => {
        if (!isInPreview) resizeTextarea(titleRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInPreview]);

    const handleImageClick = () => document.getElementById("question-image-upload").click();

    return (
        <QuizWrap>
            <FieldWrap>
                <InputWrap customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "border-red-600" : "")}>
                    {!isInPreview && (
                        <TextareaInput
                            name={`text-${selectedSlide}`}
                            value={slide.text || ""}
                            ref={titleRef}
                            handleChange={(e) => changeSlideTitle(e.target.value)}
                            label={"Titlul Slide-ului (Obligatoriu)"}
                            customStyles={slide.text ? "font-black" : "sr-only"}
                        />
                    )}
                    {isInPreview && <PreviewTextBox text={slide.text} />}
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].text`)}
                        customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].text`) ? "flex" : "hidden")}
                    />
                </InputWrap>
            </FieldWrap>

            <div className={"flex w-full flex-col sm:flex-row md:flex-col lg:flex-row"}>
                <FieldWrap customStyles={"w-full"}>
                    <InputWrap
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].description`) ? "border-red-600" : "",
                            isInPreview ? "" : "pr-4 pb-4",
                        )}>
                        {!isInPreview && (
                            <Fragment>
                                <span className={"w-full text-center font-black text-gray-400"}>
                                    Continutul Slide-ului (Obligatoriu)
                                </span>
                                <div className={"markdown max-h-96 overflow-y-scroll px-4"}>
                                    <MarkdownEditor
                                        name={`description-${selectedSlide}`}
                                        changeContent={changeSlideDescription}
                                        content={slide.description || ""}
                                        componentKey={`description-${selectedSlide}`}
                                    />
                                </div>
                            </Fragment>
                        )}
                        {isInPreview && <MarkdownPreview text={slide.description} />}
                        <ErrorSpan
                            text={retrieveErrorForPath(`slides[${slideIdx}].description`)}
                            customStyles={cn(
                                checkAnyErrorForPath(`slides[${slideIdx}].description`) ? "flex" : "hidden",
                            )}
                        />
                    </InputWrap>
                </FieldWrap>
                <div className={"col-span-1 flex w-full flex-col justify-start sm:justify-center"}>
                    <FieldWrap customStyles={"min-w-full"}>
                        <InputWrap
                            customStyles={cn(
                                checkAnyErrorForPath(`image`) ? "border-red-600" : "",
                                "bg-white flex items-center justify-center w-full",
                                "px-0",
                            )}>
                            {!isInPreview && (
                                <div
                                    onClick={handleImageClick}
                                    className={cn(
                                        checkAnyErrorForPath(`image`) ? "rounded-t-[5px]" : "rounded-[5px]",
                                        "group relative flex w-full flex-1 cursor-pointer flex-col items-center justify-center text-center text-magenta/80 transition duration-500 hover:bg-magenta/80 hover:text-white",
                                    )}>
                                    <Dropzone
                                        onDrop={async (acceptedFiles) => await changeSlideImage(acceptedFiles[0])}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dz-message needsclick;" {...getRootProps()}>
                                                <input
                                                    key={`${slide?.image ? slide?.image?.length : ""}-info-slide-upload`}
                                                    id="question-image-upload"
                                                    {...getInputProps()}
                                                />
                                            </div>
                                        )}
                                    </Dropzone>
                                    {slide.image ? (
                                        <img
                                            src={slide.image}
                                            alt="Selected File"
                                            className={cn(
                                                checkAnyErrorForPath(`slides[${slideIdx}].image`)
                                                    ? "rounded-t-[5px]"
                                                    : "rounded-[5px]",
                                                "flex w-full cursor-pointer object-cover",
                                            )}
                                        />
                                    ) : (
                                        <div className={"flex flex-col items-center space-x-4 p-4"}>
                                            <FaImage style={{ fontSize: "2.3em" }} />
                                            <p className="m-0 text-lg font-semibold text-gray-400 transition duration-500 group-hover:text-white">
                                                Atasati o poza
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isInPreview && slide.image && (
                                <img
                                    src={slide.image}
                                    alt="Imagine incarcata"
                                    className={cn(
                                        checkAnyErrorForPath(`slides[${slideIdx}].image`)
                                            ? "rounded-t-[8.1px]"
                                            : "rounded-[8.1px]",
                                        "flex aspect-[16/9] object-contain",
                                    )}
                                />
                            )}
                            <ErrorSpan
                                text={retrieveErrorForPath(`slides[${slideIdx}].image`)}
                                customStyles={cn(checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "flex" : "hidden")}
                            />
                        </InputWrap>
                    </FieldWrap>
                </div>
            </div>
        </QuizWrap>
    );
}
InfoSlide.propTypes = {
    formData: PropTypes.object.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    changeSlideImage: PropTypes.func.isRequired,
    changeSlideTitle: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    changeSlideDescription: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
