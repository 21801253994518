import ListingActions from "../../components/quizzes/listing/listing-actions.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import QuizBox from "../../components/quizzes/listing/quiz-box.jsx";
import useQuizListing from "../../hooks/quizzes/use-quiz-listing.js";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function PracticalActivityListing({ quizType }) {
    const [activities, setActivities] = useState(null);
    const { renderListing, createQuiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility } = useQuizListing(
        quizType,
        setActivities,
    );

    useEffect(() => {
        renderListing().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <ListingActions
                        newQuizAction={createQuiz}
                        refresh={renderListing}
                        newQuizzButtonName={"Adaugare activitate practica"}
                    />
                    <div className="flex flex-col gap-1 pt-4 text-lg">
                        <p>
                            <strong>
                                Atenție! Doar prima dată când completezi o activitate practică primești puncte.
                            </strong>
                        </p>
                        <ul>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Pornire{`"`}
                                </strong>
                                , înseamnă că nu ai făcut activitatea practică și vei fi punctat după completare.
                            </li>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Continuare{`"`}
                                </strong>
                                , înseamnă că ai început activitatea practică, dar nu ai terminat-o. Vei primi puncte
                                după ce o termini.
                            </li>
                            <li>
                                Dacă vezi butonul{" "}
                                <strong>
                                    {`"`}Pornire (Nepunctată){`"`}
                                </strong>
                                , înseamnă că ai terminat activitatea practică și ai primit deja punctele pentru prima
                                completare.
                            </li>
                            <li>
                                Dacă apesi pe butonul{" "}
                                <strong>
                                    {`"`}Previzualizare{`"`}
                                </strong>
                                , poti studia activitatea practică fara a fi punctat pentru completare.
                            </li>
                        </ul>
                    </div>
                    <div className="grid grid-cols-1 gap-4 py-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                        {Array.isArray(activities) &&
                            activities.length > 0 &&
                            activities.map((activity) => (
                                <QuizBox
                                    quiz={activity}
                                    key={activity.id}
                                    redirectToQuiz={redirectToQuiz}
                                    changeQuizVisibility={changeQuizVisibility}
                                    startQuizCompletion={startQuizCompletion}
                                />
                            ))}
                    </div>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
PracticalActivityListing.propTypes = {
    quizType: PropTypes.string.isRequired,
};
