import { AuthProviderContextV2 } from "./auth-provider-v2.jsx";
import { RoutesProviderContext } from "./routes-provider.jsx";
import { FetchProviderContext } from "./fetch-provider.jsx";
import { InboxProviderContext } from "./inbox-provider.jsx";
import { useContext } from "react";

export const useFetchProvider = () => {
    const context = useContext(FetchProviderContext);
    if (!context) throw new Error("Error: useFetchProvider must be used within the FetchProvider.");
    return context;
};

export const useAuthProviderV2 = () => {
    const context = useContext(AuthProviderContextV2);
    if (!context) throw new Error("useAuthProviderV2 must be used within the AuthProviderV2.");
    return context;
};

export const useRoutesProvider = () => {
    const context = useContext(RoutesProviderContext);
    if (!context) throw new Error("useRoutesProvider must be used within the RoutesProvider.");
    return context;
};

export const useInboxProvider = () => {
    const context = useContext(InboxProviderContext);
    if (!context) throw new Error("useInboxProvider must be used within the InboxProvider.");
    return context;
};
