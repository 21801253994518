import { useAuthProviderV2, useFetchProvider, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { ACTUAL_ENVIRONMENT, ENVIRONMENTS } from "../../constants/config.js";
import usePasswordChange from "../../hooks/auth/use-password-change.js";
import { Fragment, useEffect, useState } from "react";
import PasswordChange from "./password-change.jsx";
import { Link } from "react-router-dom";
import UserIcon from "./user-icon.jsx";
import PropTypes from "prop-types";

export default function TopNavBar({ children }) {
    const [profileData, setProfileData] = useState({});
    const { getProfileData } = useFetchProvider();
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();
    const { openModal, setIsModalOpen, isModalOpen, formData, handleFieldChange, errors, updatePassword } =
        usePasswordChange();

    useEffect(() => {
        async function loadProfileData() {
            try {
                setProfileData(await getProfileData());
            } catch (error) {
                console.error(error);
            }
        }

        loadProfileData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{ backgroundSize: "400px" }}
            className="sticky top-0 z-40 mb-4 w-full justify-between rounded-xl border border-lime-700 bg-[url('/bg-banner.jpg')] bg-contain bg-center bg-repeat">
            <div className="flex h-[54px] max-h-[54px] flex-1 flex-row items-center space-x-2 rounded-xl bg-white bg-opacity-30 bg-clip-border p-2">
                <Link
                    to={"/"}
                    className="-ml-3 flex min-w-[140px] items-center justify-start md:min-w-[160px] lg:min-w-[190px]">
                    <img src="/logo-ecotic.png" alt="ECOTIC logo" className="h-full max-h-[65px]" />
                </Link>
                {children}
                {ACTUAL_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT && (
                    <Fragment>
                        <span className={"hidden self-center text-red-600 lg:flex lg:text-xl lg:font-bold"}>
                            ACEASTA ESTE VARIANTA DE TESTARE A APLICATIEI!!!
                        </span>
                        <span className={"flex self-center font-bold text-red-600 lg:hidden"}>VARIANTA TESTARE!!!</span>
                    </Fragment>
                )}
                <div className={"flex w-full items-center justify-end space-x-2"}>
                    {[ROLES.ELEV, ROLES.PROFESOR].includes(userInfo?.role) && (
                        <div className={"relative flex items-center justify-center"}>
                            <div
                                className={
                                    "fond-semibold flex h-[32px] w-[100px] items-center justify-center rounded-lg border-2 border-dark bg-magenta/60 text-white"
                                }>
                                <span className={"text-nowrap font-bold"}>{profileData["codSe"] || ""}</span>
                            </div>
                            <div
                                className={
                                    "absolute -left-[8px] top-1/2 h-[16px] w-[8px] -translate-y-1/2 transform rounded-l border-2 border-dark bg-yellow-400/60"
                                }></div>
                        </div>
                    )}
                    <UserIcon openPasswordModal={openModal} profileData={profileData} />
                </div>
                <PasswordChange
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    updatePassword={updatePassword}
                    handleFieldChange={handleFieldChange}
                    formData={formData}
                    errors={errors}
                />
            </div>
        </div>
    );
}
TopNavBar.propTypes = {
    children: PropTypes.node,
};
