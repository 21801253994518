import InvalidQuizId from "../../components/quizzes/completion/invalid-quiz-id.jsx";
import ValidQuizId from "../../components/quizzes/completion/valid-quiz-id.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import useQuizCompletion from "../../hooks/quizzes/use-quiz-completion.js";
import PropTypes from "prop-types";

export default function QuizCompletion({ quizType }) {
    const {
        quizForm,
        saveAnswer,
        quizAnswers,
        removePhoto,
        currentSlide,
        isAnswerSaved,
        handleDZClick,
        isQuizIdValid,
        currentAnswer,
        isQuizFinished,
        moveToNextSlide,
        actionsDisabled,
        handleTextChange,
        redirectToListing,
        handleImageUpload,
        addOrRemoveOption,
    } = useQuizCompletion(quizType);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap withoutSideMenu={true} withoutNav={true}>
                {isQuizIdValid === false && <InvalidQuizId quizType={quizType} redirectToListing={redirectToListing} />}
                {isQuizIdValid === true && (
                    <ValidQuizId
                        quizType={quizType}
                        quizForm={quizForm}
                        saveAnswer={saveAnswer}
                        quizAnswers={quizAnswers}
                        removePhoto={removePhoto}
                        currentSlide={currentSlide}
                        isAnswerSaved={isAnswerSaved}
                        currentAnswer={currentAnswer}
                        handleDZClick={handleDZClick}
                        isQuizFinished={isQuizFinished}
                        moveToNextSlide={moveToNextSlide}
                        actionsDisabled={actionsDisabled}
                        redirectToListing={redirectToListing}
                        handleImageUpload={handleImageUpload}
                        addOrRemoveOption={addOrRemoveOption}
                        handleTextChange={handleTextChange}
                    />
                )}
            </NavBarWrap>
        </BasePageWrap>
    );
}
QuizCompletion.propTypes = {
    quizType: PropTypes.string.isRequired,
};
