import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import MarkdownPreview from "../../forms/raw-inputs/preview-markdown.jsx";
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";
import { Button } from "../../schadcn-ui/button.jsx";
import { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";

const QuizDialog = forwardRef(
    (
        { closeQuizDialog, openRejectDialog, validateQuiz, quizData, isQuizDialogOpened, quizAnswers, elementInDialog },
        ref,
    ) => {
        const { userInfo } = useAuthProviderV2();
        const { ROLES } = useRoutesProvider();

        return (
            <Fragment>
                {isQuizDialogOpened && (
                    <div className="fixed left-0 top-0 z-40 flex h-screen w-screen animate-fade cursor-default items-center justify-center bg-black/50 backdrop-blur-sm animate-duration-200">
                        <div
                            ref={ref}
                            className="relative flex max-h-[80vh] min-w-[40vw] max-w-[80vw] flex-col overflow-hidden rounded-lg bg-light p-4 shadow-lg">
                            <div className={"relative flex justify-end pb-4"}>
                                <button onClick={() => closeQuizDialog()} className="">
                                    <FaXmark />
                                </button>
                            </div>
                            <div
                                className={
                                    "relative flex h-full max-h-[80vh] w-full min-w-[40vw] max-w-[80vw] select-text flex-col space-y-4 overflow-y-scroll pr-4"
                                }>
                                {Array.isArray(quizData) && quizData.length === 0 && (
                                    <span className={"font-semibold"}>Nu exista continut verificabil.</span>
                                )}
                                {Array.isArray(quizData) &&
                                    quizData.length > 0 &&
                                    quizData.map((question, questionIdx) => {
                                        const photos = Array.isArray(quizAnswers)
                                            ? quizAnswers.find((q) => q.slideId === question.id)?.photos
                                            : null;
                                        const text = Array.isArray(quizAnswers)
                                            ? quizAnswers.find((q) => q.slideId === question.id)?.text
                                            : null;
                                        return (
                                            <div
                                                key={questionIdx}
                                                className={
                                                    "spacey-4 flex flex-col rounded-md border-2 border-magenta bg-white p-4 shadow-md"
                                                }>
                                                <span className={"font-semibold"}>{question.text}</span>
                                                {Array.isArray(photos) && photos.length > 0 && (
                                                    <div className="flex flex-wrap">
                                                        {photos.map((photo, index) => (
                                                            <div
                                                                key={index}
                                                                className="relative m-2 h-32 w-auto object-contain">
                                                                <img
                                                                    src={photo}
                                                                    alt=""
                                                                    className="h-full w-full cursor-default object-cover"
                                                                />
                                                                <button
                                                                    onClick={() => window.open(photo, "_blank")}
                                                                    className="absolute right-1 top-1 rounded-full bg-white bg-opacity-50 p-1 text-dark">
                                                                    <FaMagnifyingGlass />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {text && (
                                                    <div className="flex">
                                                        <MarkdownPreview text={text} />
                                                    </div>
                                                )}
                                                {!Array.isArray(photos) && photos.length === 0 && !text && (
                                                    <span>Nu au fost incarcat continut pentru aceasta intrebare.</span>
                                                )}
                                            </div>
                                        );
                                    })}
                                {[ROLES.ADMIN, ROLES.DBEST].includes(userInfo?.role) && (
                                    <div className={"flex flex-col space-y-4"}>
                                        <Button onClick={() => validateQuiz(elementInDialog, true, "")}>
                                            Aprobare
                                        </Button>
                                        <Button
                                            variant={"destructive"}
                                            onClick={() => openRejectDialog(elementInDialog)}>
                                            Respingere
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    },
);
QuizDialog.displayName = "QuizDialog";
QuizDialog.propTypes = {
    quizData: PropTypes.object,
    quizAnswers: PropTypes.array,
    validateQuiz: PropTypes.func.isRequired,
    closeQuizDialog: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
    elementInDialog: PropTypes.string,
    isQuizDialogOpened: PropTypes.bool.isRequired,
};

export default QuizDialog;
