import { VITE_APP_BACKEND_URL } from "../constants/config.js";
import { handleAxiosError } from "../utils.js";
import axios, { isAxiosError } from "axios";

export function useTemporaryFetch(token) {
    const temporaryFetch = axios.create({ baseURL: VITE_APP_BACKEND_URL });

    temporaryFetch.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            return handleAxiosError(error);
        },
    );

    if (token) {
        temporaryFetch.interceptors.request.use(
            async function (config) {
                config.headers = config.headers || {};
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    }

    async function temporaryPost(url, data, params) {
        try {
            const response = await temporaryFetch.post(url, data, { params });
            return response.data;
        } catch (error) {
            if (isAxiosError(error)) throw error.response?.data;
            throw error;
        }
    }

    async function temporaryPostForNewToken(refreshToken) {
        return temporaryPost("/api/auth/refresh-token", { refreshToken });
    }

    return { temporaryFetch, temporaryPost, temporaryPostForNewToken };
}
