import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import AuthorizedButtons from "./authorized-buttons.jsx";
import TeacherButtons from "./teacher-buttons.jsx";
import PupilButtons from "./pupil-buttons.jsx";
import { cn } from "../../../utils.js";
import PropTypes from "prop-types";

// eslint-disable-next-line react-refresh/only-export-components
export const QUIZ_START_TYPES = Object.freeze({
    PREVIEW: "PREVIEW",
    NEW: "NEW",
    OLD: "OLD",
});

export default function QuizBox({ quiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility }) {
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    return (
        <div
            className={cn(
                quiz.isHidden === true
                    ? [ROLES.DBEST, ROLES.ADMIN].includes(userInfo?.role)
                        ? "opacity-60"
                        : "hidden"
                    : "visible",
                "flex justify-center",
            )}>
            <div className={"relative flex w-full max-w-sm flex-col rounded-lg border border-dark bg-white shadow"}>
                <img
                    className="flex max-h-[220px] min-h-[220px] w-full rounded-t-lg object-cover"
                    src={quiz.image || "/quiz-bg.jpg"}
                    alt=""
                />
                <div className="flex h-full flex-1 flex-col justify-around p-5">
                    <div className="flex">
                        <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 sm:line-clamp-3 sm:max-h-[96px] sm:min-h-[96px] md:text-lg xl:text-2xl">
                            {quiz.title}
                        </h5>
                    </div>
                    <div className={"flex h-full flex-1 flex-col"}>
                        <p className="mb-3 line-clamp-3 h-full max-h-[72px] min-h-[72px] font-normal text-gray-700">
                            {quiz.description}
                        </p>
                        <div className={"flex h-max w-full flex-col justify-end self-end"}>
                            <AuthorizedButtons
                                quiz={quiz}
                                redirectToQuiz={redirectToQuiz}
                                changeQuizVisibility={changeQuizVisibility}
                                startQuizCompletion={startQuizCompletion}
                            />
                            <TeacherButtons
                                quiz={quiz}
                                redirectToQuiz={redirectToQuiz}
                                startQuizCompletion={startQuizCompletion}
                            />
                            <PupilButtons
                                quiz={quiz}
                                redirectToQuiz={redirectToQuiz}
                                startQuizCompletion={startQuizCompletion}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
QuizBox.propTypes = {
    quiz: PropTypes.object.isRequired,
    redirectToQuiz: PropTypes.func.isRequired,
    startQuizCompletion: PropTypes.func.isRequired,
    changeQuizVisibility: PropTypes.func.isRequired,
};
