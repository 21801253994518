import { QUESTION_SLIDE_COLORS } from "../../../../../constants/question-slide-colors.js";
import InputWrap from "../../../../forms/wraps/input-wrap.jsx";
import ErrorSpan from "../../../../layout/error-span.jsx";
import PreviewTextBox from "../../preview-text-box.jsx";
import { FaImage, FaTrashCan } from "react-icons/fa6";
import { cn } from "../../../../../utils.js";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function AnswerChoices({
    options,
    slideIdx,
    isInPreview,
    changeOptionText,
    changeOptionImage,
    removeOptionImage,
    markCorrectOption,
    checkAnyErrorForPath,
    retrieveErrorForPath,
}) {
    return (
        <div className="grid animate-fade grid-cols-1 gap-4 lg:grid-cols-2">
            {options.map((option, optionIdx) => (
                <InputWrap
                    key={optionIdx}
                    customStyles={cn(
                        (option.image || option.text) &&
                            QUESTION_SLIDE_COLORS[optionIdx] &&
                            QUESTION_SLIDE_COLORS[optionIdx].completionStyles
                            ? QUESTION_SLIDE_COLORS[optionIdx].completionStyles
                            : "bg-white ring-gray-300 border-gray-300",
                        checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}]`) ? "border-red-600" : "",
                        "bg-white w-full shadow-2xl px-0",
                    )}>
                    <div
                        className={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}]`)
                                ? "rounded-t-[5px]"
                                : "rounded-[5px]",
                            (option.image || option.text) &&
                                QUESTION_SLIDE_COLORS[optionIdx] &&
                                QUESTION_SLIDE_COLORS[optionIdx].completionStyles
                                ? QUESTION_SLIDE_COLORS[optionIdx].completionStyles
                                : "",
                            "flex h-full min-h-[50px] flex-row space-x-2 p-2",
                        )}>
                        <div className="flex h-full w-20 items-center">
                            <button
                                disabled={isInPreview}
                                onClick={() => markCorrectOption(option.id)}
                                className={cn(
                                    isInPreview ? "pointer-events-none" : "cursor-pointer",
                                    "h-6 w-6 appearance-none rounded-full bg-white text-magenta ring-2 ring-dark ring-offset-2 transition duration-300 ease-in-out",
                                    option["correct"] ? "bg-lime-600 hover:bg-lime-600" : "hover:bg-lime-400",
                                )}
                            />
                        </div>
                        {option.image ? (
                            <img
                                src={option.image}
                                alt="Uploaded"
                                className="max-h-[130px] max-w-[180px] shrink rounded-xl object-contain sm:max-h-[125px] sm:max-w-[150px] md:max-h-[125px] md:max-w-[170px] xl:max-h-[150px] xl:max-w-[280px]"
                            />
                        ) : (
                            <Fragment>
                                {!isInPreview && (
                                    <input
                                        type="text"
                                        value={option.text || ""}
                                        placeholder={`Adauga raspunsul`}
                                        onChange={(e) => changeOptionText(option.id, e.target.value)}
                                        className="w-full rounded-xl bg-transparent p-2 font-semibold focus:outline-none"
                                    />
                                )}
                                {isInPreview && (
                                    <PreviewTextBox
                                        customStyles={option.text ? "text-white text-start" : "text-gray-600"}
                                        text={option.text}
                                    />
                                )}
                            </Fragment>
                        )}

                        {!isInPreview && (
                            <div
                                className={cn(
                                    option.image ? "w-full flex-col items-end" : "flex-row justify-end space-x-2",
                                    "flex h-full",
                                )}>
                                {option.image && (
                                    <div className={"flex h-full w-8 items-center"}>
                                        <FaTrashCan
                                            className={"cursor-pointer"}
                                            onClick={() => removeOptionImage(option.id)}
                                        />
                                    </div>
                                )}
                                <div className={"flex h-full w-8 items-center"}>
                                    <Dropzone
                                        onDrop={(acceptedFiles) => changeOptionImage(option.id, acceptedFiles[0])}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dz-message needsclick" {...getRootProps()}>
                                                <input
                                                    key={`${option?.image ? option?.image?.length : ""}-${optionIdx}-question-image`}
                                                    {...getInputProps({ accept: "image/*" })}
                                                />
                                                <FaImage className="cursor-pointer" />
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        )}
                    </div>
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].options[${optionIdx}].id`)}
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}].id`)
                                ? "flex bg-white rounded-b-[7.5px]"
                                : "hidden",
                        )}
                    />
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].options[${optionIdx}].number`)}
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}].number`)
                                ? "flex bg-white rounded-b-[7.5px]"
                                : "hidden",
                        )}
                    />
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].options[${optionIdx}].text`)}
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}].text`)
                                ? "flex bg-white rounded-b-[7.5px]"
                                : "hidden",
                        )}
                    />
                    <ErrorSpan
                        text={retrieveErrorForPath(`slides[${slideIdx}].options[${optionIdx}].image`)}
                        customStyles={cn(
                            checkAnyErrorForPath(`slides[${slideIdx}].options[${optionIdx}].image`)
                                ? "flex bg-white rounded-b-[7.5px]"
                                : "hidden",
                        )}
                    />
                </InputWrap>
            ))}
        </div>
    );
}
AnswerChoices.propTypes = {
    options: PropTypes.array.isRequired,
    slideIdx: PropTypes.number.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    changeOptionText: PropTypes.func.isRequired,
    markCorrectOption: PropTypes.func.isRequired,
    changeOptionImage: PropTypes.func.isRequired,
    removeOptionImage: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
