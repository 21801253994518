import { useAuthProviderV2, useFetchProvider, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { ACTUAL_ENVIRONMENT, ENVIRONMENTS } from "../../../constants/config.js";
import DoubleCheckAction from "../../reports/tables/double-check-action.jsx";
import { updateFailureToast, updateSuccessToast } from "../../../toast.js";
import { cn, getQuizPlaceholder } from "../../../utils.js";
import { Button } from "../../schadcn-ui/button.jsx";
import { QUIZ_START_TYPES } from "./quiz-box.jsx";
import { Slide, toast } from "react-toastify";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";

export default function AuthorizedButtons({ redirectToQuiz, quiz, startQuizCompletion, changeQuizVisibility }) {
    const [loading, setLoading] = useState(false);
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    const { postQuzSyncToProd } = useFetchProvider();

    async function SendQuizToProd() {
        setLoading(true);
        const toastId = toast.loading(
            `${getQuizPlaceholder(quiz.type, 4)} ${quiz.title} se trimite in varianta de productie a aplicatiei. Va rugam asteptati.`,
            { transition: Slide },
        );
        try {
            await postQuzSyncToProd(quiz.id);
            updateSuccessToast("Operatiunea a fost efectuata cu succes!", toastId);
        } catch (error) {
            console.error(error);
            updateFailureToast(
                error.message ||
                    `Acasta ${getQuizPlaceholder(quiz.type, 2)} nu a putut fi salvata. Va rugam sa incercati mai tarziu!`,
                toastId,
            );
        } finally {
            setLoading(false);
        }
    }

    if ([ROLES.DBEST, ROLES.ADMIN].includes(userInfo?.role))
        return (
            <Fragment>
                <Button
                    variant={"link"}
                    className={"w-full sm:w-auto"}
                    onClick={() => changeQuizVisibility(quiz["id"], !quiz["isHidden"])}>
                    <span className={"text-wrap"}>{quiz["isHidden"] ? "Afisare" : "Ascundere"}</span>
                </Button>
                <Button variant={"link"} className={"w-full sm:w-auto"} onClick={() => redirectToQuiz(quiz)}>
                    <span className={"text-wrap"}>Clonare</span>
                </Button>
                <Button
                    variant={"link"}
                    className={"w-full, sm:w-auto"}
                    onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.PREVIEW)}>
                    <span className={"text-wrap"}>Previzualizare</span>
                </Button>
                {ACTUAL_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT && !quiz.isHidden && (
                    <DoubleCheckAction
                        buttonStyles={cn(
                            "inline-flex items-center justify-center whitespace-nowrap rounded-md text-small font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
                            "underline-offset-4 hover:underline",
                            "h-9 px-4 py-2",
                            "w-full, sm:w-auto",
                        )}
                        actionsDisabled={loading}
                        buttonMessage={"Trimite in Productie"}
                        spanMessage={`Doriti sa trimiteti in productie ${getQuizPlaceholder(quiz.type, 3)}: ${quiz.title}?`}
                        yesAction={() => SendQuizToProd()}
                    />
                )}
            </Fragment>
        );
}
AuthorizedButtons.propTypes = {
    quiz: PropTypes.object.isRequired,
    redirectToQuiz: PropTypes.func.isRequired,
    startQuizCompletion: PropTypes.func.isRequired,
    changeQuizVisibility: PropTypes.func.isRequired,
};
