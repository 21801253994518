export const ENVIRONMENTS = Object.freeze({
    PRODUCTION: "PRODUCTION",
    DEVELOPMENT: "DEVELOPMENT",
});
export const ACTUAL_ENVIRONMENT = Object.freeze(ENVIRONMENTS.PRODUCTION);
export const PRODUCTION_BACKEND_URL = Object.freeze("https://backend-ecotic.azurewebsites.net/api");
export const LOCAL_BACKEND_URL = Object.freeze("https://backend-ecotic-dev.azurewebsites.net/api");
export const BASE_FETCH_URL = Object.freeze(
    ACTUAL_ENVIRONMENT === ENVIRONMENTS.PRODUCTION
        ? PRODUCTION_BACKEND_URL
        : ACTUAL_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT
          ? LOCAL_BACKEND_URL
          : null,
);

export const ECOTIC_ID = Object.freeze("ec410f81-05ea-4c89-8961-7e473a30c89d");

export const VITE_TOKEN_KEY = "ecotic-prod-auth-token";
export const VITE_REFRESH_TOKEN_KEY = "ecotic-prod-auth-refreshToken";
export const VITE_APP_BACKEND_URL = "https://backend-ecotic.azurewebsites.net";

export const VITE_REDIRECT_PATHNAME_KEY = "ecotic-prod-redirect-pathname";
export const VITE_REDIRECT_SEARCH_KEY = "ecotic-prod-redirect-search";
export const VITE_REDIRECT_HASH_KEY = "ecotic-prod-redirect-hash";
