export const QUESTION_SLIDE_COLORS = Object.freeze({
    0: { completionStyles: "bg-teal-500 ring-teal-700 border-teal-700 text-white" },
    1: { completionStyles: "bg-blue-500 ring-blue-700 border-blue-700 text-white" },
    2: { completionStyles: "bg-lime-500 ring-lime-700 border-lime-700 text-white" },
    3: { completionStyles: "bg-orange-500 ring-orange-700 border-orange-700 text-white" },
    4: { completionStyles: "bg-purple-500 ring-purple-700 border-purple-700 text-white" },
    5: { completionStyles: "bg-pink-500 ring-pink-700 border-pink-700 text-white" },
    6: { completionStyles: "bg-red-500 ring-red-700 border-red-700 text-white" },
    7: { completionStyles: "bg-cyan-500 ring-cyan-700 border-cyan-700 text-white" },
    8: { completionStyles: "bg-green-500 ring-green-700 border-green-700 text-white" },
    9: { completionStyles: "bg-indigo-500 ring-indigo-700 border-indigo-700 text-white" },
    10: { completionStyles: "bg-cyan-500 ring-cyan-700 border-cyan-700 text-white" },
    11: { completionStyles: "bg-amber-500 ring-amber-700 border-amber-700 text-white" },
});
