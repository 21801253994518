import { useAuthProviderV2, useFetchProvider, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import { updateFailureToast, updateSuccessToast } from "../../toast.js";
import { delay, findChangedPath } from "../../utils.js";
import { useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";

const DEFAULT_FORM = Object.freeze({ email: "", parola: "" });

export default function useLogIn() {
    const [logInData, setLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [oldLogInData, setOldLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const { updateToken, updateRefreshToken } = useAuthProviderV2();
    const { PATHS, PAGES, ROLES } = useRoutesProvider();
    const { publicFetch } = useFetchProvider();

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...logInData };
        updatedData[name] = value;
        setLogInData(updatedData);
    };

    const redirectToRegister = (role) => {
        if (ROLES.ELEV === role) return navigate(PATHS[PAGES.PUPIL_REGISTER]);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const id = toast.loading("Se incarca...", { transition: Slide });
        const standardError = "Am intamplinat probleme autentificare.";
        const successMessage = "Ati fost conectat cu succes!";

        try {
            await delay(1000);
            const { data } = await publicFetch.post("/auth/authenticate", logInData);
            const { token, refreshToken } = data;
            updateSuccessToast(successMessage, id);
            updateRefreshToken(refreshToken);
            updateToken(token);
        } catch (error) {
            console.error(error);
            if (error.response.data.message || error.response.data.errors) {
                if (error.response.data.errors && Object.keys(error.response.data.errors).length > 0)
                    setErrors(error.response.data.errors);
                if (error.response.data.message) {
                    updateFailureToast(error.response.data.message, id);
                } else updateFailureToast(standardError, id);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(logInData, oldLogInData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldLogInData(_.cloneDeep(logInData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logInData, errors]);

    return {
        handleSubmit,
        handleChange,
        redirectToRegister,
        loading,
        logInData,
        errors,
    };
}
