import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import AuthorizationAction from "./authorization-action.jsx";
import { formatUnixTimestamp } from "../../../utils.js";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function TableBody({
    tableData,
    openDialogForm,
    openNotesDialog,
    actionsDisabled,
    authorizeAccount,
    openRejectDialog,
    openEmailChangeDialog,
}) {
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"flex justify-between space-x-1 font-bold"}>
                                    <AuthorizationAction
                                        authorizeAccount={authorizeAccount}
                                        openRejectDialog={openRejectDialog}
                                        actionsDisabled={actionsDisabled}
                                        row={row}
                                    />
                                </div>
                            </BodyCell>
                        )}

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"flex justify-between space-x-1 font-bold"}>
                                    <button
                                        disabled={actionsDisabled}
                                        onClick={() => openNotesDialog(row["userId"], row["phoneCallNote"])}
                                        className={
                                            "text-nowrap hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                                        }>
                                        Adauga Notite
                                    </button>
                                </div>
                            </BodyCell>
                        )}

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"flex justify-between space-x-1 font-bold"}>
                                    {row["role"] !== ROLES.ELEV && row["hasSchoolRegistrationFormCompleted"] && (
                                        <Fragment>
                                            <button
                                                disabled={actionsDisabled}
                                                onClick={() => openDialogForm(row["userId"], false)}
                                                className={
                                                    "hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                                                }>
                                                Previzualizare
                                            </button>
                                            {!row["isEnabled"] && row["hasSchoolRegistrationFormCompleted"] && (
                                                <span>/</span>
                                            )}
                                        </Fragment>
                                    )}
                                    {!row["isEnabled"] && row["hasSchoolRegistrationFormCompleted"] && (
                                        <button
                                            disabled={actionsDisabled}
                                            onClick={() => openDialogForm(row["userId"], true)}
                                            className={
                                                "hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                                            }>
                                            Corectare
                                        </button>
                                    )}
                                </div>
                            </BodyCell>
                        )}

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"flex justify-between space-x-1 font-bold"}>
                                    <button
                                        disabled={actionsDisabled}
                                        onClick={() => openEmailChangeDialog(row["userId"])}
                                        className={
                                            "text-nowrap hover:underline focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline"
                                        }>
                                        Schimbare Email
                                    </button>
                                </div>
                            </BodyCell>
                        )}

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["isEnabled"] === true ? "DA" : row["isEnabled"] === false ? "NU" : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["hasSchoolRegistrationFormCompleted"] === true
                                    ? "DA"
                                    : row["hasSchoolRegistrationFormCompleted"] === false
                                      ? "NU"
                                      : ""}
                            </div>
                        </BodyCell>

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1} customStyles={"min-w-[350px] max-w-[350px]"}>
                                <div className={"text-wrap"}>{row["phoneCallNote"] || ""}</div>
                            </BodyCell>
                        )}

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["rejectReason"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["timestampInregistrare"] ? formatUnixTimestamp(row["timestampInregistrare"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["timestampFormularInscriere"]
                                    ? formatUnixTimestamp(row["timestampFormularInscriere"])
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["telefon"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["nume"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["email"] || ""}</div>
                        </BodyCell>

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>{row["numeInstitutieInvatamant"] || ""}</div>
                            </BodyCell>
                        )}

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>{row["codSiiirUnitate"] || ""}</div>
                            </BodyCell>
                        )}

                        {userInfo?.role === ROLES.ADMIN && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>{row["codSe"] || ""}</div>
                            </BodyCell>
                        )}
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    openDialogForm: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    authorizeAccount: PropTypes.func.isRequired,
    openNotesDialog: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
    openEmailChangeDialog: PropTypes.func.isRequired,
};
