import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import { formatUnixTimestamp } from "../../../utils.js";
import ApprovalAction from "./approval-action.jsx";
import { Button } from "../../schadcn-ui/button.jsx";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";

export default function TableBody({ tableData, openRejectDialog, validateQuiz, actionsDisabled, openQuizDialog }) {
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        {[ROLES.DBEST, ROLES.ADMIN].includes(userInfo?.role) && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>
                                    <ApprovalAction
                                        row={row}
                                        actionsDisabled={actionsDisabled}
                                        openRejectDialog={openRejectDialog}
                                        validateQuiz={validateQuiz}
                                    />
                                </div>
                            </BodyCell>
                        )}

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {(row["isApproved"] !== null ||
                                    !["Activitate practica", "Provocare"].includes(row["lesson"]["typeAlias"])) && (
                                    <span>N/a</span>
                                )}
                                {row["isApproved"] === null &&
                                    ["Activitate practica", "Provocare"].includes(row["lesson"]["typeAlias"]) && (
                                        <Button
                                            variant={"link"}
                                            className={"fond-semibold px-0"}
                                            disabled={actionsDisabled}
                                            onClick={() =>
                                                openQuizDialog(
                                                    row["lesson"]["id"],
                                                    row["version"],
                                                    row["user"]["id"],
                                                    row["id"],
                                                )
                                            }>
                                            <span className={"font-semibold"}>Deschidere completarea</span>
                                        </Button>
                                    )}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["isApproved"] === true ? "DA" : row["isApproved"] === false ? "NU" : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["rejectReason"]}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["timestamp"] ? formatUnixTimestamp(row["timestamp"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["lesson"] && row["lesson"]["typeAlias"] ? row["lesson"]["typeAlias"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["lesson"] && row["lesson"]["title"] ? row["lesson"]["title"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["user"] && row["user"]["nume"] ? row["user"]["nume"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["user"] && row["user"]["email"] ? row["user"]["email"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["user"] &&
                                row["user"]["school"] &&
                                row["user"]["school"]["numeInstitutieInvatamant"]
                                    ? row["user"]["school"]["numeInstitutieInvatamant"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["user"] && row["user"]["school"] && row["user"]["school"]["codSe"]
                                    ? row["user"]["school"]["codSe"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["points"]}</div>
                        </BodyCell>
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    validateQuiz: PropTypes.func.isRequired,
    openQuizDialog: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
};
