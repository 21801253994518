export const FILE_FOLDERS = Object.freeze({
    WASTE_COLLECTION_FORM_PHOTOS: "WASTE_COLLECTION_FORM_PHOTOS", //ok
    PARENT_SIGNATURES: "PARENT_SIGNATURES", //ok
    LESSON_ANSWER_PHOTOS: "LESSON_ANSWER_PHOTOS", //ok
    LESSON_IMAGES: "LESSON_IMAGES", //ok
    SLIDE_IMAGES: "SLIDE_IMAGES", //ok
    OPTION_IMAGES: "OPTION_IMAGES", //ok
    USER_PROTOCOLS: "USER_PROTOCOLS", //Ok
    DEFAULT: "DEFAULT", //OK
});
