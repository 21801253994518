import { SLIDE_TYPES } from "../../../../pages/quizzes/quiz-creation.jsx";
import PreviewSlideBox from "./preview-slide-box.jsx";
import { cn } from "../../../../utils.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

export default function PreviewSlidesBar({ quizForm, quizAnswers, currentSlide, isQuizFinished }) {
    const sortedSlides = useMemo(
        () => JSON.parse(JSON.stringify(quizForm)).slides.sort((a, b) => a.number - b.number),
        [quizForm],
    );

    return (
        <div
            style={{ backgroundSize: "400px" }}
            className="mt-4 max-h-[64px] min-h-[64px] w-full rounded-xl border border-lime-700 bg-[url('/bg-banner.jpg')] bg-contain bg-center bg-repeat sm:mr-4 sm:mt-0 sm:max-h-full sm:min-h-full sm:min-w-[64px] sm:max-w-[64px]">
            <div className="h-full w-full rounded-xl bg-white bg-opacity-70 bg-clip-border px-2 pb-2">
                <div
                    className={
                        "flex h-full w-full flex-row space-x-2 overflow-x-scroll pb-2 pt-2 sm:flex-col sm:space-x-0 sm:space-y-2 sm:overflow-x-hidden sm:overflow-y-scroll sm:pb-0 sm:pr-2"
                    }>
                    <PreviewSlideBox
                        content={"☆"}
                        customStyles={cn(
                            (![undefined, null].includes(currentSlide) && !isQuizFinished) ||
                                (currentSlide === null && isQuizFinished)
                                ? "bg-blue-300 border-blue-500"
                                : "border-dark",
                        )}
                    />
                    {sortedSlides.map((slide, index) => {
                        const answer = quizAnswers.find((answer) => answer.slideId === slide.id);
                        const customBoxStyles = answer
                            ? slide.type !== SLIDE_TYPES.QUESTION
                                ? "bg-blue-300 border-blue-500"
                                : slide.options &&
                                    answer.selectedOptionsIds &&
                                    slide.options.filter((option) => option.correct)?.length ===
                                        answer.selectedOptionsIds.length &&
                                    !slide.options.find(
                                        (option) => !option.correct && answer.selectedOptionsIds.includes(option.id),
                                    )
                                  ? "bg-green-300 border-green-500"
                                  : "bg-red-300 border-red-500"
                            : "";
                        console.log(slide);
                        console.log(quizAnswers);
                        return (
                            <PreviewSlideBox
                                key={index}
                                content={(index + 1).toString()}
                                withClock={slide.type === SLIDE_TYPES.QUESTION}
                                customStyles={customBoxStyles}
                            />
                        );
                    })}
                    <PreviewSlideBox
                        content={"★"}
                        customStyles={cn(currentSlide === null && isQuizFinished ? "bg-blue-300 border-blue-500" : "")}
                    />
                </div>
            </div>
        </div>
    );
}

PreviewSlidesBar.propTypes = {
    currentSlide: PropTypes.string,
    isQuizFinished: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    quizAnswers: PropTypes.array.isRequired,
};
