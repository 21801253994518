import { updateFailureToast, updateSuccessToast } from "../../toast.js";
import { useAuthProviderV2, useFetchProvider } from "../../providers/provider-hooks.jsx";
import { delay, findChangedPath } from "../../utils.js";
import { useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import _ from "lodash";

const DEFAULT_FORM = Object.freeze({ resetPasswordToken: "", newPassword: "" });

export default function useNewPass() {
    const [logInData, setLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [oldLogInData, setOldLogInData] = useState(JSON.parse(JSON.stringify(DEFAULT_FORM)));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { token } = useParams();

    const { postNewPassAfterReset } = useFetchProvider();
    const { logout } = useAuthProviderV2();

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...logInData };
        updatedData[name] = value;
        setLogInData(updatedData);
    };

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);

        const toastId = toast.loading("Se incarca...", { transition: Slide });
        const standardError = "Am intamplinat probleme.";
        const successMessage = "Parola a fost resetata. Veti fi redirectionat la pagina principala!";

        try {
            await delay(1000);
            await postNewPassAfterReset({ ...logInData, resetPasswordToken: token });
            updateSuccessToast(successMessage, toastId);
            await delay(1000);
            logout();
            return navigate("/informatii-despre-scoala-ecoterrienilor");
        } catch (error) {
            console.error(error);
            if (error.response.data.message || error.response.data.errors) {
                if (error.response.data.errors && Object.keys(error.response.data.errors).length > 0)
                    setErrors(error.response.data.errors);
                if (error.response.data.message) updateFailureToast(error.response.data.message, toastId);
                return;
            }
            updateFailureToast(standardError, toastId);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(logInData, oldLogInData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find((errorKey) => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldLogInData(_.cloneDeep(logInData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logInData, errors]);

    return {
        handleSubmit,
        handleChange,
        loading,
        logInData,
        errors,
    };
}
