import { jwtDecode } from "jwt-decode";

export function useTokenActions() {
    function isTokenValid(token) {
        if (!token) return false;
        try {
            const decoded = jwtDecode(token);
            return !!decoded.exp;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    function isTokenExpired(token) {
        if (!token) return true;
        try {
            const decoded = jwtDecode(token);
            return new Date().getTime() / 1000 > (decoded.exp || 0);
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    function retrieveTokenFromStorage(storageKey) {
        return localStorage.getItem(storageKey);
    }

    function setTokenInStorage(storageKey, token) {
        localStorage.setItem(storageKey, token || "");
    }

    return { isTokenValid, isTokenExpired, retrieveTokenFromStorage, setTokenInStorage };
}
