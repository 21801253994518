import ListingActions from "../../components/quizzes/listing/listing-actions.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import QuizBox from "../../components/quizzes/listing/quiz-box.jsx";
import useQuizListing from "../../hooks/quizzes/use-quiz-listing.js";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function ChallengesListing({ quizType }) {
    const [monthlyChallenges, setMonthlyChallenges] = useState(null);
    const { renderListing, createQuiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility } = useQuizListing(
        quizType,
        setMonthlyChallenges,
    );

    useEffect(() => {
        renderListing().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <ListingActions
                        newQuizAction={createQuiz}
                        refresh={renderListing}
                        newQuizzButtonName={"Adaugare provocare lunara"}
                    />
                    <div
                        className={
                            "grid grid-cols-1 gap-4 py-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
                        }>
                        {Array.isArray(monthlyChallenges) &&
                            monthlyChallenges.length > 0 &&
                            monthlyChallenges.map((h) => (
                                <QuizBox
                                    quiz={h}
                                    key={h.id}
                                    redirectToQuiz={redirectToQuiz}
                                    changeQuizVisibility={changeQuizVisibility}
                                    startQuizCompletion={startQuizCompletion}
                                />
                            ))}
                    </div>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
ChallengesListing.propTypes = {
    quizType: PropTypes.string.isRequired,
};
