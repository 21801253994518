import { useAuthProviderV2, useRoutesProvider } from "./providers/provider-hooks.jsx";
import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";

export default function App() {
    const { validatePermissionsForPath, getRedirectComponent } = useAuthProviderV2();
    const { NAV_LINKS_V2, PAGES } = useRoutesProvider();

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route exact path={"/"} element={getRedirectComponent()} />

                <Route path={NAV_LINKS_V2[PAGES.HOME].PATH} element={NAV_LINKS_V2[PAGES.HOME].COMPONENT} />

                <Route
                    path={NAV_LINKS_V2[PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD].PATH}
                    element={NAV_LINKS_V2[PAGES.NEW_PASS_FOR_FORGOTTEN_PASSWORD].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.TEACHER_AUTH].PATH}
                    element={NAV_LINKS_V2[PAGES.TEACHER_AUTH].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.FORGOTTEN_PASSWORD].PATH}
                    element={NAV_LINKS_V2[PAGES.FORGOTTEN_PASSWORD].COMPONENT}
                />

                <Route path={NAV_LINKS_V2[PAGES.PUPIL_AUTH].PATH} element={NAV_LINKS_V2[PAGES.PUPIL_AUTH].COMPONENT} />

                <Route
                    path={NAV_LINKS_V2[PAGES.PUPIL_REGISTER].PATH}
                    element={NAV_LINKS_V2[PAGES.PUPIL_REGISTER].COMPONENT}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].PATH,
                        NAV_LINKS_V2[PAGES.BIG_SCHOOLS_DATA_TABLE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PUPILS_REPORT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PUPILS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.PUPILS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].PATH,
                        NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_QUIZ_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_LISTING].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_CREATION_REDIRECT].COMPONENT,
                    )}
                />

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_LISTING].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_CREATION_REDIRECT].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path={NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].PATH,
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_FORM].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].PATH,
                        NAV_LINKS_V2[PAGES.WASTE_COLLECTION_TABLE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.OUTBOX_V2].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.OUTBOX_V2].PATH,
                        NAV_LINKS_V2[PAGES.OUTBOX_V2].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.INBOX].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.INBOX].PATH,
                        NAV_LINKS_V2[PAGES.INBOX].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].PATH,
                        NAV_LINKS_V2[PAGES.SENT_NOTIFICATIONS].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNTS_AUTHORIZATION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH,
                        NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_COMPLETION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.LESSON_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.HOMEWORK_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].PATH,
                        NAV_LINKS_V2[PAGES.MONTHLY_CHALLENGES_COMPLETION_PREVIEW].COMPONENT,
                    )}
                />

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.DAILY_CHALLENGES_COMPLETION_PREVIEW].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path={NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.SCHOOLS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.PROFILE].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.PROFILE].PATH,
                        NAV_LINKS_V2[PAGES.PROFILE].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].PATH,
                        NAV_LINKS_V2[PAGES.COMPLETED_QUIZZES].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.NOTIFICATIONS_REPORT].COMPONENT,
                    )}
                />

                <Route
                    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].PATH,
                        NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].COMPONENT,
                    )}
                />

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path={NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH}*/}
                {/*    element={validatePermissionsForPath(*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH,*/}
                {/*        NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].COMPONENT,*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path={NAV_LINKS_V2[PAGES.FINAL_REPORT].PATH}
                    element={validatePermissionsForPath(
                        NAV_LINKS_V2[PAGES.FINAL_REPORT].PATH,
                        NAV_LINKS_V2[PAGES.FINAL_REPORT].COMPONENT,
                    )}
                />

                <Route path={"*"} element={getRedirectComponent()} />
            </Routes>
        </Suspense>
    );
}
