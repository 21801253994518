import { useAuthProviderV2, useFetchProvider, useRoutesProvider } from "./provider-hooks.jsx";
import useTableParams from "../hooks/reports/use-table-params.js";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toastOneWarn } from "../toast.js";
import { delay } from "../utils.js";
import PropTypes from "prop-types";

const InboxProviderContext = createContext(null);
const { Provider } = InboxProviderContext;

const InboxProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [openedNotification, setOpenedNotification] = useState("");
    const [pagination, setPagination] = useState({});
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const { params, changeParams } = useTableParams(renderNotifications, {
        page: 0,
    });

    const { getNotifications, patchNotificationAsRead } = useFetchProvider();
    const { userInfo, token } = useAuthProviderV2();
    const { PATHS, PAGES, NAV_LINKS_V2 } = useRoutesProvider();
    const location = useLocation();

    async function readNotification(id) {
        const targetNotif = notifications.find((notif) => notif.id === id)?.content;
        if (!targetNotif) return toastOneWarn("Nu exista continut pentru aceasta notificare");
        if (!targetNotif["isRead"]) {
            try {
                await patchNotificationAsRead(id);
            } catch (error) {
                console.error(error);
            }
        }
        await renderNotifications();
        return setOpenedNotification(targetNotif);
    }

    async function renderNotifications(freshParams) {
        if (userInfo) {
            await delay(1000);
            setActionsDisabled(true);
            try {
                const data = await getNotifications(freshParams || params);
                if (data.content.length === 0 && location.pathname === PATHS[PAGES.INBOX]) {
                    return toastOneWarn("Nu exista notificari disponibile");
                }
                setNotifications(data.content);
                setPagination({
                    totalPages: data.totalPages,
                    ...data["pageable"],
                    first: data.first,
                    last: data.last,
                });
            } catch (error) {
                console.error(error);
            } finally {
                setActionsDisabled(false);
            }
        }
    }

    useEffect(() => {
        if (userInfo && token && [PATHS[PAGES.INBOX]].includes(location.pathname)) renderNotifications().then();
    }, [location?.pathname, token]);

    const inboxProviderValue = {
        pagination,
        changeParams,
        notifications,
        actionsDisabled,
        readNotification,
        openedNotification,
        setOpenedNotification,
    };

    return <Provider value={inboxProviderValue}>{children}</Provider>;
};

InboxProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { InboxProviderContext, InboxProvider };
