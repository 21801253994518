import { RoutesProvider } from "./providers/routes-provider.jsx";
import { InboxProvider } from "./providers/inbox-provider.jsx";
import { FetchProvider } from "./providers/fetch-provider.jsx";
import AuthProviderV2 from "./providers/auth-provider-v2.jsx";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";
import App from "./app.jsx";
import "./main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <RoutesProvider>
            <AuthProviderV2>
                <FetchProvider>
                    <InboxProvider>
                        <App />
                        <ToastContainer style={{ pointerEvents: "auto" }} />
                    </InboxProvider>
                </FetchProvider>
            </AuthProviderV2>
        </RoutesProvider>
    </BrowserRouter>,
);
