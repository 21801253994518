import { toast, Slide } from "react-toastify";
import { delay } from "./utils.js";

export const defaultToastOptions = {
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Slide,
    theme: "light",
};

export function toastOneError(message) {
    toast.error(message, defaultToastOptions);
}

export function toastOneInfo(message) {
    toast.info(message, defaultToastOptions);
}

export function toastOneWarn(message) {
    toast.warn(message, defaultToastOptions);
}

export function toastOneSuccess(message) {
    toast.success(message, defaultToastOptions);
}

export async function toastMultipleErrors(messages) {
    for (const message of messages) {
        await delay(300);
        toastOneError(message);
    }
}

export async function toastMultipleWarns(messages) {
    for (const message of messages) {
        await delay(300);
        toastOneWarn(message);
    }
}

export function updateSuccessToast(successMessage, id) {
    toast.update(id, {
        render: successMessage,
        type: "success",
        isLoading: false,
        ...defaultToastOptions,
        autoClose: 3000,
    });
}

export function updateFailureToast(failureMessage, id) {
    toast.update(id, {
        render: failureMessage,
        type: "error",
        isLoading: false,
        ...defaultToastOptions,
        autoClose: 3000,
    });
}

export function handleUnhandledError(id) {
    const message = "Am intampinat o eroare necunoscuta.";
    if (id) return updateFailureToast(message, id);
    return toastOneError(message);
}
