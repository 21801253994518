import { useAuthProviderV2, useRoutesProvider } from "../../../providers/provider-hooks.jsx";
import DoubleCheckAction from "../tables/double-check-action.jsx";
import { cn, formatUnixTimestamp } from "../../../utils.js";
import { Button } from "../../schadcn-ui/button.jsx";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";
import { Fragment } from "react";

export default function TableBody({
    tableData,
    validateOrder,
    currentEdition,
    actionsDisabled,
    openRejectDialog,
    openOrderDialogForm,
    openLogisticsDialog,
}) {
    const { userInfo } = useAuthProviderV2();
    const { ROLES } = useRoutesProvider();

    return (
        <tbody className={"bg-light"}>
            {tableData.map((row, rowIdx) => {
                return (
                    <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                        {[ROLES.DBEST, ROLES.ADMIN].includes(userInfo?.role) && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>
                                    {row["isValidated"] === null && (
                                        <Fragment>
                                            <DoubleCheckAction
                                                buttonMessage={"Aprobare"}
                                                actionsDisabled={actionsDisabled}
                                                yesAction={() => validateOrder(row["id"], true)}
                                                spanMessage={"Sunteti sigur ca doriti sa aprobati comanda?"}
                                                buttonStyles={cn(
                                                    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-small font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
                                                    "underline-offset-4 hover:underline",
                                                    "px-0 disabled:cursor-not-allowed text-green-700 font-semibold",
                                                )}
                                            />
                                            <span> / </span>
                                            <Button
                                                variant={"link"}
                                                disabled={actionsDisabled}
                                                onClick={() => openRejectDialog(row["id"])}
                                                className="px-0 font-semibold text-red-700 disabled:cursor-not-allowed">
                                                Respingere
                                            </Button>
                                            <span> / </span>
                                            <Button
                                                variant={"link"}
                                                disabled={actionsDisabled}
                                                onClick={() => openOrderDialogForm(row["id"], row["school"]["id"])}
                                                className="px-0 font-semibold disabled:cursor-not-allowed">
                                                Verificare
                                            </Button>
                                        </Fragment>
                                    )}
                                    {row["isValidated"] !== null && <span className={"font-semibold"}>N/a</span>}
                                </div>
                            </BodyCell>
                        )}
                        {[ROLES.DBEST, ROLES.ADMIN].includes(userInfo?.role) && (
                            <BodyCell colSpan={1}>
                                <div className={"text-nowrap"}>
                                    {row["isValidated"] && row["isProcessed"] && (
                                        <Button
                                            variant="link"
                                            disabled={actionsDisabled}
                                            onClick={() => openLogisticsDialog(row)}
                                            className="px-0 font-semibold disabled:cursor-not-allowed">
                                            Modificare cantitati deseuri manuala
                                        </Button>
                                    )}
                                </div>
                            </BodyCell>
                        )}

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {Array.isArray(row["photos"]) &&
                                    row["photos"].length > 0 &&
                                    row["photos"].map((photoUrl, photoIdx) => (
                                        <Fragment key={photoIdx}>
                                            <span className={"font-semibold"}>{photoIdx !== 0 ? " / " : ""}</span>
                                            <Button
                                                variant={"link"}
                                                className={"px-0 font-semibold"}
                                                onClick={() => window.open(photoUrl, "_blank")}>
                                                Poza {photoIdx + 1}
                                            </Button>
                                        </Fragment>
                                    ))}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["deeeOrderDocument"] && typeof row["deeeOrderDocument"] === "string" && (
                                    <Button
                                        variant={"link"}
                                        className={"px-0 font-semibold"}
                                        onClick={() => window.open(row["deeeOrderDocument"], "_blank")}>
                                        Document DEEE
                                    </Button>
                                )}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["dbaOrderDocument"] && typeof row["dbaOrderDocument"] === "string" && (
                                    <Button
                                        variant={"link"}
                                        className={"px-0 font-semibold"}
                                        onClick={() => window.open(row["dbaOrderDocument"], "_blank")}>
                                        Document DBA
                                    </Button>
                                )}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{currentEdition.nume || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["timestamp"] ? formatUnixTimestamp(row["timestamp"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["validatedAt"] ? formatUnixTimestamp(row["validatedAt"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["deeeOrderProcessedDate"]
                                    ? formatUnixTimestamp(row["deeeOrderProcessedDate"])
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["dbaOrderProcessedDate"] ? formatUnixTimestamp(row["dbaOrderProcessedDate"]) : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["dataOrientativaColectare"]
                                    ? formatUnixTimestamp(row["dataOrientativaColectare"])
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["codSiiirUnitate"]
                                    ? row["school"]["codSiiirUnitate"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deeeOrderNumber"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["dbaOrderNumber"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["numeInstitutieInvatamant"]
                                    ? row["school"]["numeInstitutieInvatamant"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["codSe"] ? row["school"]["codSe"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["cui"] ? row["school"]["cui"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["professor"] && row["school"]["professor"]["nume"]
                                    ? row["school"]["professor"]["nume"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["professor"] && row["school"]["professor"]["email"]
                                    ? row["school"]["professor"]["email"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["school"] && row["school"]["professor"] && row["school"]["professor"]["telefon"]
                                    ? row["school"]["professor"]["telefon"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["judet"]
                                    ? row["pickupAddress"]["judet"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["oras"]
                                    ? row["pickupAddress"]["oras"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["comuna"]
                                    ? row["pickupAddress"]["comuna"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["sat"] ? row["pickupAddress"]["sat"] : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["strada"]
                                    ? row["pickupAddress"]["strada"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["numar"]
                                    ? row["pickupAddress"]["numar"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["bloc"]
                                    ? row["pickupAddress"]["bloc"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["scara"]
                                    ? row["pickupAddress"]["scara"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["etaj"]
                                    ? row["pickupAddress"]["etaj"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["apartament"]
                                    ? row["pickupAddress"]["apartament"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["pickupAddress"] && row["pickupAddress"]["codPostal"]
                                    ? row["pickupAddress"]["codPostal"]
                                    : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["contactPersonName"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["contactPersonPhone"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["contactPersonEmail"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["deeeKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["dbaKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["becuriSiNeoaneKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["realDeeeKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["realDbaKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["realBecuriSiNeoaneKg"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["isValidated"] === true ? "DA" : row["isValidated"] === false ? "NU" : ""}
                            </div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["rejectReason"] || ""}</div>
                        </BodyCell>

                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>
                                {row["isProcessed"] === true ? "DA" : row["isProcessed"] === false ? "NU" : ""}
                            </div>
                        </BodyCell>
                    </BodyRow>
                );
            })}
        </tbody>
    );
}
TableBody.propTypes = {
    currentEdition: PropTypes.object,
    tableData: PropTypes.array.isRequired,
    validateOrder: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
    openLogisticsDialog: PropTypes.func.isRequired,
    openOrderDialogForm: PropTypes.func.isRequired,
};
