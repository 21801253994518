import { matchPath, Navigate, useLocation, useNavigate } from "react-router-dom";
import { createContext, useMemo, useState, useEffect, Fragment } from "react";
import { useTemporaryFetch } from "../hooks/use-temporary-fetch.js";
import { useTokenActions } from "../hooks/use-token-actions.js";
import { useRoutesProvider } from "./provider-hooks.jsx";
import { toastOneInfo } from "../toast.js";
import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";
import {
    VITE_TOKEN_KEY,
    VITE_REDIRECT_HASH_KEY,
    VITE_REFRESH_TOKEN_KEY,
    VITE_REDIRECT_SEARCH_KEY,
    VITE_REDIRECT_PATHNAME_KEY,
} from "../constants/config.js";

const AuthProviderContextV2 = createContext(null);
const { Provider } = AuthProviderContextV2;
export default function AuthProviderV2({ children }) {
    const { isTokenValid, isTokenExpired, retrieveTokenFromStorage, setTokenInStorage } = useTokenActions();
    const [token, setToken] = useState(retrieveTokenFromStorage(VITE_TOKEN_KEY));
    const [refreshToken, setRefreshToken] = useState(retrieveTokenFromStorage(VITE_REFRESH_TOKEN_KEY));
    const userInfo = useMemo(() => (token ? jwtDecode(token) : null), [token]);

    const { temporaryPostForNewToken } = useTemporaryFetch();
    const { ROLES, PATHS, PAGES, getUserHomePage, NAV_LINKS_V2, getListsOfPagePathsGroupedByType } =
        useRoutesProvider();

    const { hash, search, pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    function logout() {
        try {
            setToken(null);
            setRefreshToken(null);
            setTokenInStorage(VITE_TOKEN_KEY, "");
            setTokenInStorage(VITE_REFRESH_TOKEN_KEY, "");
        } catch (error) {
            console.error("Error when performing a logout:", error);
        }
    }

    function getRedirectComponent() {
        try {
            const tokenValid = isTokenValid(token);
            const tokenExpired = isTokenExpired(token);

            if (!tokenValid || tokenExpired) {
                return <Navigate to={PATHS[PAGES.HOME]} />;
            }
            return <Navigate to={getUserHomePage(userInfo?.role)} />;
        } catch (error) {
            console.error("Error when getting the redirect component:", error);
            return <Fragment />;
        }
    }

    function performRedirect() {
        function performAuthorizedRedirect() {
            console.log("performAuthorizedRedirect");
            // const localStoragePathname = localStorage.getItem(VITE_REDIRECT_PATHNAME_KEY) || "";
            // const localStorageSearch = localStorage.getItem(VITE_REDIRECT_SEARCH_KEY) || "";
            // const localStorageHash = localStorage.getItem(VITE_REDIRECT_HASH_KEY) || "";
            //
            // localStorage.removeItem(VITE_REDIRECT_PATHNAME_KEY);
            // localStorage.removeItem(VITE_REDIRECT_SEARCH_KEY);
            // localStorage.removeItem(VITE_REDIRECT_HASH_KEY);

            // console.log("localStoragePathname", !!localStoragePathname);
            // if (!localStoragePathname) return navigate(getUserHomePage(userInfo?.role));
            // const navLinksArray = Object.values(NAV_LINKS_V2);

            if (userInfo?.role === ROLES.ELEV && !userInfo?.isEnabled) {
                return navigate(PATHS[PAGES.PARENTAL_APPROVAL]);
            }

            if (
                userInfo?.role === ROLES.PROFESOR &&
                !userInfo?.isEnabled &&
                userInfo?.["hasSchoolRegistrationFormCompleted"]
            ) {
                window.history.replaceState(null, "ECOTIC", NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH);
                return navigate(PATHS[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER]);
            }

            // for (const link of navLinksArray) {
            //     if (matchPath(link.PATH, localStoragePathname)) {
            //         if (link.ALLOWED_ROLES.some((role) => role === userInfo?.role)) {
            //             const fullUrl = `${localStoragePathname}${localStorageSearch}${localStorageHash}`;
            //             return navigate(fullUrl);
            //         }
            //     }
            // }

            return navigate(getUserHomePage(userInfo?.role));
        }

        try {
            const tokenValid = isTokenValid(token);
            const tokenExpired = isTokenExpired(token);
            console.log("token", token);
            console.log("tokenValid", tokenValid);
            console.log("tokenExpired", tokenExpired);

            if (!tokenValid || tokenExpired) {
                logout();
                navigate(PATHS[PAGES.HOME]);
            } else return performAuthorizedRedirect();
        } catch (error) {
            console.error("Error when performing the redirect:", error);
        }
    }

    function updateRefreshToken(newToken) {
        try {
            if (!newToken) return logout();
            setRefreshToken(newToken);
            setTokenInStorage(VITE_REFRESH_TOKEN_KEY, newToken);
        } catch (error) {
            console.error("Error when updating the refreshToken:", error);
        }
    }

    function updateToken(newToken) {
        try {
            if (!newToken) return logout();
            const tokenValid = isTokenValid(newToken);
            const tokenExpired = isTokenExpired(newToken);
            if (!tokenValid || tokenExpired) return logout();
            setToken(newToken);
            setTokenInStorage(VITE_TOKEN_KEY, newToken);
        } catch (error) {
            console.error("Error when updating the token:", error);
        }
    }

    function validatePermissionsForPath(name, component) {
        function redirectToLogin() {
            const { securedPaths } = getListsOfPagePathsGroupedByType();
            if (securedPaths.find((securedPath) => matchPath(securedPath, pathname))) {
                localStorage.setItem(VITE_REDIRECT_PATHNAME_KEY, pathname);
                localStorage.setItem(VITE_REDIRECT_SEARCH_KEY, search);
                localStorage.setItem(VITE_REDIRECT_HASH_KEY, hash);
            }
            return <Navigate to={PATHS[PAGES.HOME]} />;
        }

        const navLinksArray = Object.values(NAV_LINKS_V2);

        if (userInfo?.role === ROLES.ELEV && !userInfo?.isEnabled) {
            return NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].COMPONENT;
        }

        if (
            userInfo?.role === ROLES.PROFESOR &&
            !userInfo?.isEnabled &&
            userInfo?.["hasSchoolRegistrationFormCompleted"]
        ) {
            return NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].COMPONENT;
        }

        for (const link of navLinksArray) {
            if (matchPath(link.PATH, name)) {
                if (link.ALLOWED_ROLES.length === 0) return component;
                if (!token || !userInfo) return redirectToLogin();

                const tokenValid = isTokenValid(token);
                const tokenExpired = isTokenExpired(token);
                if (!tokenValid || tokenExpired) {
                    logout();
                    return redirectToLogin();
                }

                if (link.ALLOWED_ROLES.some((role) => role === userInfo?.role)) return component;
                return redirectToLogin();
            }
        }
        return redirectToLogin();
    }

    function checkLocalStorage() {
        try {
            const tokenValue = retrieveTokenFromStorage(VITE_TOKEN_KEY);
            const refreshTokenValue = retrieveTokenFromStorage(VITE_REFRESH_TOKEN_KEY);
            if (!tokenValue && !refreshTokenValue) return logout();
            if (JSON.stringify(token) !== JSON.stringify(tokenValue)) updateToken(tokenValue);
            if (JSON.stringify(refreshToken) !== JSON.stringify(refreshTokenValue))
                updateRefreshToken(refreshTokenValue);
        } catch (error) {
            console.error("Error checking localstorage for token changes:", error);
        }
    }

    function validateTokenChange() {
        console.log("validating token change");
        try {
            const { unsecuredPaths } = getListsOfPagePathsGroupedByType();

            if (!token && !refreshToken) {
                if (unsecuredPaths.some((path) => matchPath(path, pathname))) return;
                if (!unsecuredPaths.some((path) => matchPath(path, pathname))) {
                    performRedirect();
                    return;
                }
            }

            if (token && refreshToken && userInfo) {
                if (unsecuredPaths.some((path) => matchPath(path, pathname))) {
                    console.log("hit 1");
                    performRedirect();
                    return;
                }
                console.log("hit 2");
                const currentPage = Object.values(NAV_LINKS_V2).find((page) => matchPath(page.PATH, pathname));
                if (!currentPage) {
                    performRedirect();
                    return;
                }
                if (!currentPage.ALLOWED_ROLES.includes(userInfo?.role)) {
                    performRedirect();
                    return;
                }
            }
        } catch (error) {
            console.error("Error validating token change:", error);
        }
    }

    async function handleTokenRefresh(forced = false) {
        console.log("checking token");
        try {
            const { unsecuredPaths } = getListsOfPagePathsGroupedByType();
            if (unsecuredPaths.some((path) => matchPath(path, pathname))) return;
            if (!token || !refreshToken) return logout();

            const decodedToken = jwtDecode(token);
            if (!decodedToken || !decodedToken.exp) return logout();

            const currentTime = Math.floor(Date.now() / 1000);
            const tokenTimeRemaining = decodedToken.exp - currentTime;

            if (tokenTimeRemaining > 120 && !forced) return;
            console.log("fetching token");
            try {
                const { token: newToken, refreshToken: newRefreshToken } = await temporaryPostForNewToken(refreshToken);
                updateToken(newToken);
                updateRefreshToken(newRefreshToken);
                return newToken;
            } catch (error) {
                console.error(error);
                toastOneInfo("Sesiunea dumneavoastră a expirat. Vă rugăm să vă conectați încă o dată.");
                return logout();
            }
        } catch (error) {
            console.error("Error refreshing token:", error);
            toastOneInfo("Sesiunea dumneavoastră a expirat. Vă rugăm să vă conectați încă o dată.");
            return logout();
        }
    }

    useEffect(() => {
        handleTokenRefresh().then();
        const interval = setInterval(async () => {
            await handleTokenRefresh();
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, [location.pathname, token, refreshToken]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === VITE_TOKEN_KEY || event.key === VITE_REFRESH_TOKEN_KEY) {
                checkLocalStorage();
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    useEffect(() => {
        validateTokenChange();
    }, [token, refreshToken]);

    const authProviderValue = {
        token,
        logout,
        userInfo,
        updateToken,
        refreshToken,
        performRedirect,
        updateRefreshToken,
        handleTokenRefresh,
        getRedirectComponent,
        validatePermissionsForPath,
    };

    return <Provider value={authProviderValue}>{children}</Provider>;
}

AuthProviderV2.propTypes = { children: PropTypes.node.isRequired };
export { AuthProviderContextV2, AuthProviderV2 };
